<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="matchDataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">请输入1688产品路径 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入1688产品路径" v-model="data_form.product_url" name="product_url">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

            </div>

            <div class="card-footer">
                <button type="button" ref="data_form_submit" class="btn btn-primary mr-2" @click="saveForm()">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>

    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    import {getRemoteAliProduct} from '@/api/purchasement';

    export default {
        name: "match_ali_product",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {

        },
        components :{

        },
        data() {
            return {
                data_form:{
                    product_id: this.dialog_data.form_data.product_id,
                    product_url: ''
                },
                step: 1
            }
        },
        mounted() {

        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            saveForm() {
                var _this = this;
                const data_form = KTUtil.getById("matchDataForm");

                if(_this.step == 1) {

                    this.fv = formValidation(data_form, {
                        fields: {
                            product_url: {
                                validators: {
                                    callback: {
                                        message: '1688产品详情路径格式错误',
                                        callback: function (value, validator) {
                                            if (value.value.indexOf('offer/') > 0 && value.value.indexOf('.html?') > 0) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        plugins: {
                            trigger: new Trigger(),
                            submitButton: new SubmitButton(),
                            bootstrap: new Bootstrap()
                        }
                    });
                }
                this.fv.on("core.form.valid", () => {
                    var _this = this;

                    //按钮加载动画
                    const submitButton = this.$refs["data_form_submit"];
                    submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                    if(_this.step == 1){

                        //地址产品地址格式
                        if(_this.data_form.product_url.indexOf('offer/') > 0 && _this.data_form.product_url.indexOf('.html?') > 0){
                            var ali_product_id = _this.data_form.product_url.substring(_this.data_form.product_url.indexOf('offer/') + 6,_this.data_form.product_url.indexOf('.html?'));

                            //获取1688产品详细信息
                            getRemoteAliProduct({'ali_product_id': ali_product_id}).then(function(res) {
                                if(res && res.response) {

                                } else {

                                }
                            });

                        }
                    }
                });

                this.fv.validate();
            }
        }
    }
</script>
