<template>
    <v-app style="background: transparent" :class="{}">
        <!-- begin:: Content Head -->
        <KTSubheader
                v-if="nav.subheaderDisplay"
                v-bind:breadcrumbs="nav.breadcrumbs"
                v-bind:title="nav.pageTitle"
        />
        <!-- end:: Content Head -->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">

                <form class="form fv-plugins-bootstrap fv-plugins-framework" id="dataForm" novalidate="novalidate">

                    <div class="card card-custom example example-compact">
                        <div class="card-header">
                            <h3 class="card-title">采购信息</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row ">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">采购类型 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <b-form-select class="form-control" v-model="purchasement.channel" :options="channel_list" name="channel">

                                    </b-form-select>
                                </div>
                            </div>

                            <div class="form-group row " v-if="purchasement.channel == 1 || purchasement.channel == 2">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">采购仓库 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <b-form-select class="form-control" v-model="purchasement.warehouse_id" :options="warehouse_list" name="warehouse_id">

                                    </b-form-select>
                                </div>
                            </div>

                            <div class="form-group row ">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">备注:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                <textarea class="form-control" v-model="purchasement.note"
                                          name="SEO_desc" rows="5">
                                </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Card-->




                    <div class="card card-custom example example-compact mt-10">
                        <div class="card-header">
                            <h3 class="card-title">产品信息</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row" v-if="purchasement.channel == 1 || purchasement.channel == 2">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">采购产品 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4">
                                    <treeselect
                                            :load-options="loadOptions"
                                            :default-expand-level="1"
                                            :async="true"
                                            placeholder="请选择产品"
                                            v-model="purchasement.product_id"
                                            @select="selectProduct"
                                    />
                                    <input type="hidden" v-model="purchasement.product_id" name="product_id"/>
                                </div>
                                <div class="col-lg-4" v-if="purchasement.channel == 2 && purchasement.product_id && !purchasement.ali_product">
                                    <label class="col-form-label" @click="matchAliProduct">该产品还未配对，是否开始 <a href="javascript:void(0);">配对</a>？</label>
                                </div>
                            </div>

                            <div class="form-group table-responsive" v-if="purchasement.product_id">
                                <table class="table ">
                                    <thead>
                                    <tr class="alert alert-light">
                                        <th scope="col">SKU</th>
                                        <th scope="col" width="30%">属性</th>
                                        <th scope="col" width="10%">价格</th>
                                        <th scope="col" width="10%">数量</th>
                                        <th scope="col" width="10%">金额</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <template v-for="(item, idx) in purchasement.details">
                                        <tr :key="idx">
                                            <td>{{item.sku}}</td>
                                            <td>
                                                <template v-for="attr in JSON.parse(item.attr_info)">
                                                    <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                                </template>
                                            </td>

                                            <td><input type="text" @input="onFloatInput" name="price"  v-model="purchasement.details[idx].price" class="form-control" placeholder=""></td>
                                            <td><input type="text" @input="onIntInput" name="quantity"  v-model="purchasement.details[idx].quantity" class="form-control" placeholder=""></td>
                                            <td><input type="text" disabled="disabled" @input="onIntInput" name="amount"  v-model="purchasement.details[idx].amount" class="form-control" placeholder=""></td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>

                            <div class="form-group row" v-if="purchasement.channel == 3">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">产品:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="product_title"
                                           v-model="purchasement.product_title">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">运费:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           @input="onFloatInput"
                                           name="shipping_amount"
                                           v-model="purchasement.shipping_amount">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">其他费用:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           @input="onFloatInput"
                                           name="other_amount"
                                           v-model="purchasement.other_amount">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">总费用:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           @input="onFloatInput"
                                           name="amount"
                                           v-model="purchasement.amount">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Card-->

                    <div class="card card-custom example example-compact mt-10">
                        <div class="card-header">
                            <h3 class="card-title">供应商信息</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row ">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">供应商 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <b-form-select class="form-control" v-model="purchasement.supplier_id" :options="supplier_list" name="supplier_id">

                                    </b-form-select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">联系方式:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="email_skype"
                                           v-model="purchasement.phone"
                                           disabled="disabled">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">收款人:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="supplier_payee"
                                           v-model="purchasement.supplier_payee">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">支付方式:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="supplier_channel"
                                           v-model="purchasement.supplier_channel">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">收款账号:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="supplier_account"
                                           v-model="purchasement.supplier_account">
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-lg-9 ml-lg-auto">
                                    <button ref="data_form_submit" type="submit" class="btn btn-primary mr-2"> 保存</button>
                                    <a href="/purchasement" class="btn btn-light-primary">返回</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>

        <v-dialog v-model="dialog.show" persistent max-width="1200px">
            <component
                    :key="dialog.id"
                    :title="dialog.title"
                    :visible="dialog.show"
                    :is="dialog.view"
                    :dialog_data="dialog.data"
                    @opeareResultHandler="opeareResultHandler"></component>
        </v-dialog>

    </v-app>
</template>

<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTUtil from "@/assets/js/components/util";
    import TEditor from '@/view/content/tinymce/Index'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Treeselect from '@riophae/vue-treeselect'
    import matchAliProduct from '@/view/pages/purchasement/MatchAliProduct';

    import {getSupplier, getProduct, getWareHouse, getProductSku, addPurchasement, getAliProduct} from '@/api/purchasement';

    export default {
        name: "add_purchasement_view",
        components: {
            KTSubheader,
            TEditor,
            Treeselect
        },
        created: function(){
            var _this = this;
            getSupplier().then(function(res) {
                if(res && res.response) {
                    _this.supplier_cache = res.response;
                    _this.supplier_list = KTUtil.listToSelectOptions(res.response, 'id', 'name');
                }
            });
            getWareHouse().then(function(res) {
                if(res && res.response) {
                    _this.warehouse_list = KTUtil.listToSelectOptions(res.response, 'id', 'name');
                }
            });

        },
        watch: {
            'purchasement.supplier_id': function(val){
                var _this = this;
                if(_this.purchasement.supplier_id && _this.supplier_cache && _this.supplier_cache.length > 0){
                    _this.supplier_cache.forEach(function(value){
                        if(value['id'] == _this.purchasement.supplier_id){
                            _this.purchasement.phone = value.phone;
                            _this.purchasement.supplier_channel = value.channel;
                            _this.purchasement.supplier_account = value.account;
                            _this.purchasement.supplier_payee = value.payee;
                            _this.purchasement.supplier_name = value.name;
                            return;
                        }
                    });
                } else {
                    _this.purchasement.phone = '';
                    _this.purchasement.supplier_channel = '';
                    _this.purchasement.supplier_account = '';
                    _this.purchasement.supplier_payee = '';
                    _this.purchasement.supplier_name = '';
                }
            },
            'purchasement.product_id': function(val){
                var _this = this;
                if(_this.purchasement.product_id) {

                    //判断采购类型，区分1688采购和普通采购
                    if(_this.purchasement.channel == 1) {
                        getProductSku({'product_id': _this.purchasement.product_id}).then(function (res) {
                            if (res && res.response) {
                                _this.purchasement.details = res.response;
                            }
                        });
                    } else if(_this.purchasement.channel == 2) {

                        //检查产品是否进行配对
                        getAliProduct({'product_id': _this.purchasement.product_id}).then(function(res) {
                            if(res && res.response) {
                                _this.purchasement.ali_product = res.response;
                            } else {
                                KTUtil.confirm('操作提示', '该产品还未进行1688配对，是否开始配对？', function(){
                                    _this.matchAliProduct();
                                });
                            }
                        });
                    }
                }
            },
            'purchasement.details': {
                handler (val, oldval) {
                   this.calculateAmount();
                },
                deep: true
            },
            'purchasement.shipping_amount': function(val){
                this.calculateAmount();
            },
            'purchasement.other_amount': function(val){
                this.calculateAmount();
            }

        },
        data() {
            return {
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/purchasement', 'title':'采购管理'}, {'id':'', 'route':'/purchasement/addpurchasement', 'title':'添加采购'}],
                    pageTitle: '仓库'
                },
                channel_list: [{'value': 1, 'text': '手工采购'}, {'value': 2, 'text': '1688采购'}, {'value': 3, 'text': '其他采购'}],
                supplier_list: [],
                supplier_cache: [],
                warehouse_list: [],
                purchasement: {
                    channel: 1,
                    supplier_id: '',
                    phone: '',
                    supplier_name: '',
                    supplier_channel: '',
                    supplier_account: '',
                    supplier_payee: '',
                    product_id: null,
                    product_title: '',
                    details: [],
                    shipping_amount: '',
                    other_amount: '',
                    amount: '',
                    note:'',
                    ali_product: null
                },

                dialog: {
                    id: 0,
                    show: false,
                    title: '',
                    view: null,
                    data: null
                }

            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    channel: {
                        validators: {
                            notEmpty: {
                                message: "采购类型不能为空"
                            }
                        }
                    },

                    supplier_id: {
                        validators: {
                            notEmpty: {
                                message: "采购供应商不能为空"
                            }
                        }
                    },


                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                addPurchasement(_this.purchasement).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$router.push({path: '/purchasement'});
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {

            opeareResultHandler(result) {
                if(result){

                }
                this.dialog.show = false;
            },
            onFloatInput(e){
                e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
            },
            onIntInput(e){

                e.target.value = parseInt(e.target.value) || null;
            },
            loadOptions({ action, searchQuery, callback }) {
                if (action === 'ASYNC_SEARCH') {
                    getProduct({'product_title': searchQuery}).then(function (res) {
                        if(res && res.response){
                            var options = [];
                            res.response.forEach(function(value){
                                options.push({'id': value.id, 'label': value.site_name +'  '+ value.product_title});
                            });
                            callback(null, options);
                        }
                    });
                }

            },
            calculateAmount() {
                var _this = this;
                var amount = 0;
                _this.purchasement.details.forEach(function(value, index){
                    var sum = value.quantity * value.price;
                    if(sum) {
                        amount += sum;
                        _this.purchasement.details[index].amount = sum;
                    }
                });
                if(_this.purchasement.shipping_amount) {
                    amount += parseFloat(_this.purchasement.shipping_amount);
                }
                if(_this.purchasement.other_amount) {
                    amount += parseFloat(_this.purchasement.other_amount);
                }
                _this.purchasement.amount = amount?amount:'';
            },
            selectProduct(val){
                this.purchasement.product_title = val.label;
            },
            matchAliProduct() {
                this.dialog.id = new Date().getTime();
                this.dialog.show = true;
                this.dialog.title = '1688产品匹配';
                this.dialog.view = matchAliProduct;
                this.dialog.data = {
                    form_data: {
                        'product_id': this.purchasement.product_id
                    }
                };
            }

        }
    }
</script>
